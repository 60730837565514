var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppWindow", {
    attrs: { title: _vm.title },
    scopedSlots: _vm._u([
      {
        key: "actionButtons",
        fn: function() {
          return [
            _c(
              "button",
              {
                staticClass: "header-button btn btn-sm",
                attrs: { title: "Report an issue" },
                on: {
                  click: function($event) {
                    _vm.showReportIssue = true
                  }
                }
              },
              [_c("BIconBug")],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c("v3dafi"),
            _vm.showReportIssue
              ? _c("ReportIssueForm", {
                  on: {
                    close: function($event) {
                      _vm.showReportIssue = false
                    }
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }