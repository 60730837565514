import { render, staticRenderFns } from "./ServerNotices.vue?vue&type=template&id=84b43850&scoped=true&"
import script from "./ServerNotices.vue?vue&type=script&lang=js&"
export * from "./ServerNotices.vue?vue&type=script&lang=js&"
import style0 from "./ServerNotices.vue?vue&type=style&index=0&id=84b43850&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84b43850",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2384520030/src/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('84b43850')) {
      api.createRecord('84b43850', component.options)
    } else {
      api.reload('84b43850', component.options)
    }
    module.hot.accept("./ServerNotices.vue?vue&type=template&id=84b43850&scoped=true&", function () {
      api.rerender('84b43850', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ServerNotices.vue"
export default component.exports