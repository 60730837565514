var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "main-menu" },
    [
      _vm._t("default", function() {
        return _vm._l(_vm.displayedItems, function(item, key) {
          return _c(
            "MenuItem",
            _vm._b(
              {
                key: key,
                attrs: { active: _vm.active === key },
                on: {
                  click: function($event) {
                    return _vm.$emit("change", key)
                  }
                }
              },
              "MenuItem",
              item,
              false
            )
          )
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }