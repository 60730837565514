var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container-app",
      class: {
        "container-app-ow": _vm.isInOverwolf,
        "container-app-presenter": _vm.presenterMode
      }
    },
    [
      _vm.isInOverwolf
        ? _c(
            "div",
            { staticClass: "container-header left navbar-dark" },
            [
              _c("b-navbar-brand", { attrs: { title: _vm.version } }, [
                _c("img", {
                  staticClass: "expanded",
                  attrs: {
                    src:
                      _vm.BASE_URL +
                      "/images/AugmentLogo_Horizontal_black_backgr.svg",
                    alt: "Augment"
                  }
                }),
                _c("span", { staticClass: "navbar-brand__tag" }, [
                  _vm._v(_vm._s(_vm.backendName))
                ])
              ])
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "container-header left navbar-dark" },
            [
              _c("b-navbar-brand", { attrs: { to: "/", title: _vm.version } }, [
                _c("img", {
                  staticClass: "expanded",
                  attrs: {
                    src:
                      _vm.BASE_URL +
                      "/images/AugmentLogo_Horizontal_black_backgr.svg",
                    alt: "Augment"
                  }
                })
              ]),
              _c(
                "b-navbar-toggle",
                { attrs: { target: "nav-collapse" } },
                [_c("IconMenu")],
                1
              )
            ],
            1
          ),
      _c(
        "div",
        { staticClass: "container-main left" },
        [
          _c(
            "b-collapse",
            {
              staticClass: "px-3 pb-3",
              attrs: { id: "nav-collapse", "is-nav": "" }
            },
            [
              _c("portal-target", {
                staticClass: "navbar-nav",
                attrs: { name: "menu", multiple: "", tag: "ul" }
              }),
              _c(
                "b-navbar-nav",
                [
                  _vm.canAccessPublicMatches
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            active: _vm.$route.path === "/matches",
                            to: "/matches"
                          }
                        },
                        [_vm._v(" Pro Matches ")]
                      )
                    : _vm._e(),
                  _vm.canAccessGridEmeaMatches
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            active:
                              _vm.$route.path === "/grid/matches/upcoming",
                            to: "/grid/matches/upcoming"
                          }
                        },
                        [_vm._v(" Upcoming Matches ")]
                      )
                    : _vm._e(),
                  _vm.canAccessGridEmeaMatches
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            active: _vm.$route.path === "/grid/matches",
                            to: "/grid/matches"
                          }
                        },
                        [_vm._v(" Grid Matches ")]
                      )
                    : _vm._e(),
                  false
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            active: _vm.$route.path === "/collegiate/matches",
                            to: "/collegiate/matches"
                          }
                        },
                        [_vm._v(" Collegiate Matches ")]
                      )
                    : _vm._e(),
                  _c("b-nav-item", { attrs: { disabled: "" } }, [
                    _vm._v(" Teams ")
                  ]),
                  _vm.canAccessScrimsV1
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            active: _vm.$route.path === "/scrims",
                            to: "/scrims"
                          }
                        },
                        [_vm._v(" Scrims ")]
                      )
                    : _vm._e(),
                  _vm.canAccessGridScrims
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            active: _vm.$route.path === "/grid/scrims",
                            to: "/grid/scrims"
                          }
                        },
                        [_vm._v(" Grid Scrims ")]
                      )
                    : _vm._e(),
                  _vm.canAccessScrimsV2
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            active: _vm.$route.path === "/scrims2",
                            to: "/scrims2"
                          }
                        },
                        [_vm._v(" Overwolf Scrims ")]
                      )
                    : _vm._e(),
                  _c("b-nav-item", { attrs: { disabled: "" } }, [
                    _vm._v(" Maps ")
                  ])
                ],
                1
              ),
              _c(
                "b-navbar-nav",
                { staticClass: "mt-auto" },
                [
                  _c(
                    "b-nav-item",
                    {
                      attrs: {
                        active: _vm.$route.name === "Feedback",
                        to: "/feedback"
                      }
                    },
                    [_vm._v(" Submit feedback ")]
                  ),
                  !_vm.is_authenticated
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            active: _vm.$route.name === "Login",
                            to: "/login"
                          }
                        },
                        [_vm._v(" Login ")]
                      )
                    : _vm._e(),
                  _vm.is_authenticated
                    ? _c(
                        "b-nav-item-dropdown",
                        {
                          attrs: {
                            "menu-class": "bg-dark px-3 pb-3",
                            text: _vm.username
                          },
                          on: {
                            click: function($event) {
                              _vm.profileMenuOpen = !_vm.profileMenuOpen
                            }
                          }
                        },
                        [
                          _c(
                            "b-nav-item",
                            {
                              attrs: {
                                active: _vm.$route.name === "Profile",
                                to: "/profile"
                              }
                            },
                            [_vm._v(" Account ")]
                          ),
                          _c(
                            "b-nav-item",
                            {
                              attrs: {
                                active: _vm.$route.name === "Billing",
                                to: "/billing"
                              }
                            },
                            [_vm._v(" Billing ")]
                          ),
                          _c(
                            "b-nav-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$emit("logout")
                                }
                              }
                            },
                            [_vm._v(" Log out ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("portal-target", { attrs: { slim: "", name: "sidebar-left" } })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container-header center" },
        [_c("portal-target", { attrs: { slim: "", name: "header-center" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "container-main center" },
        [_c("router-view")],
        1
      ),
      !_vm.presenterMode
        ? _c(
            "div",
            { staticClass: "container-header right" },
            [
              _c("portal-target", { attrs: { slim: "", name: "header-right" } })
            ],
            1
          )
        : _vm._e(),
      !_vm.presenterMode
        ? _c(
            "div",
            { staticClass: "container-main right" },
            [
              _c("portal-target", {
                attrs: { slim: "", name: "sidebar-right" }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }