var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "app-header", attrs: { id: "header" } }, [
    _c("img", {
      attrs: { src: require("../../assets/overwolf/header_icon.svg") }
    }),
    _c("h1", [_vm._v(_vm._s(_vm.title))]),
    _c(
      "div",
      { staticClass: "window-controls-group" },
      [
        _vm._t("actionButtons"),
        _c("button", {
          staticClass: "window-control window-control-minimize btn btn-sm",
          attrs: { id: "minimizeButton" }
        }),
        _c("button", {
          staticClass: "window-control window-control-maximize btn btn-sm",
          attrs: { id: "maximizeButton" }
        }),
        _c("button", {
          staticClass: "window-control window-control-close btn btn-sm",
          attrs: { id: "closeButton" }
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }