var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bug-report" }, [
    _c("div", { staticClass: "bug-report-modal" }, [
      _c("span", { staticClass: "bug-report-modal-title" }, [
        _vm._v("Report an issue")
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.email,
            expression: "email"
          }
        ],
        staticClass: "form-control",
        attrs: {
          type: "email",
          id: "email",
          name: "email",
          placeholder: "Type your email",
          required: "",
          disabled: _vm.loading
        },
        domProps: { value: _vm.email },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.email = $event.target.value
          }
        }
      }),
      _c("br"),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.message,
            expression: "message"
          }
        ],
        staticClass: "form-control",
        attrs: {
          id: "bug-description",
          name: "bug-description",
          placeholder: "Tell us what happened",
          disabled: _vm.loading,
          rows: "6",
          maxlength: "450"
        },
        domProps: { value: _vm.message },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.message = $event.target.value
          }
        }
      }),
      _c("span", { staticClass: "counter" }, [
        _vm._v(_vm._s(_vm.message.length || 0) + "/450")
      ]),
      _c("br"),
      _c("div", { staticClass: "bug-report-modal-buttons" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary",
            attrs: { type: "button", disabled: _vm.loading },
            on: { click: _vm.close }
          },
          [_vm._v("Close")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button", disabled: _vm.loading },
            on: { click: _vm.sendReport }
          },
          [
            _vm.loading
              ? _c("span", [_vm._v("Sending...")])
              : _c("span", [_vm._v("Send")])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }