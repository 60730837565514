var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.layout, {
    tag: "component",
    attrs: { "backend-name": _vm.backendName },
    on: { backend: _vm.changeBackend, logout: _vm.logout }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }