var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "menu-item", class: _vm.classes },
    [
      _c(
        _vm.subactive
          ? "button"
          : _vm.to
          ? "router-link"
          : _vm.href
          ? "a"
          : "button",
        {
          tag: "button",
          staticClass: "btn btn-primary menu-btn",
          class: _vm.classes,
          attrs: {
            type: "button",
            disabled: _vm.disabled,
            "data-tag": !this.subactive ? _vm.tag : null,
            href: !_vm.subactive ? _vm.href : null,
            to: !_vm.subactive ? _vm.to : null
          },
          on: {
            click: function($event) {
              !_vm.disabled && _vm.$emit("click")
            }
          }
        },
        [
          _vm._t("default", function() {
            return [
              _vm._v(" " + _vm._s(_vm.label) + " "),
              _vm.notice
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.noninteractive",
                          modifiers: { hover: true, noninteractive: true }
                        }
                      ],
                      staticClass: "badge badge-danger",
                      attrs: { title: _vm.notice }
                    },
                    [_vm._v("!")]
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }