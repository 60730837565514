var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "navbar-collapsed": _vm.navbarCollapsed },
      attrs: { id: "app" }
    },
    [
      _c(
        "div",
        { staticClass: "scroll-root app-nav" },
        [
          _c("AppNavigation", {
            attrs: {
              "backend-name": _vm.backendName,
              collapsed: _vm.navbarCollapsed
            },
            on: {
              "update:collapsed": function($event) {
                _vm.navbarCollapsed = $event
              },
              backend: _vm.$listeners.backend,
              logout: _vm.$listeners.logout
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "scroll-root app-content",
          class: "page-" + _vm.$route.name
        },
        [
          _c("WorkboxUpdates"),
          _c("ServerNotices"),
          _c("router-view", { key: _vm.$route.name })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }