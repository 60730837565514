var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-window" },
    [
      _vm.isOverwolfWindow
        ? _c("WindowHeader", {
            attrs: { title: _vm.title },
            scopedSlots: _vm._u(
              [
                {
                  key: "actionButtons",
                  fn: function() {
                    return [_vm._t("actionButtons")]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _c("div", { staticClass: "app-content" }, [_vm._t("content")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }