var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notices" },
    _vm._l(_vm.notices, function(notice, index) {
      return _c("Transition", { key: index }, [
        _c(
          "details",
          {
            staticClass: "alert alert-dismissible fade show",
            class: _vm.noticeClass(notice),
            attrs: { role: "alert" }
          },
          [
            _c("summary", { staticClass: "alert-heading" }, [
              _vm._v(
                " " +
                  _vm._s(notice.severity.toUpperCase()) +
                  ": " +
                  _vm._s(notice.title) +
                  " "
              ),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "aria-label": "Close" },
                  on: {
                    click: function($event) {
                      return _vm.hide(notice)
                    }
                  }
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×")
                  ])
                ]
              )
            ]),
            _c("div", {
              staticClass: "alert-body",
              domProps: { innerHTML: _vm._s(notice.description) }
            })
          ]
        )
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }