var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.prompt
        ? _c("Transition", [
            _c(
              "div",
              {
                staticClass: "alert alert-dismissible fade show alert-info",
                attrs: { role: "alert" }
              },
              [
                _c("div", { staticClass: "alert-heading" }, [
                  _vm._v(" New version is available. "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.activate }
                    },
                    [_vm._v("Activate it now")]
                  )
                ])
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }